<script setup>
import { ref, defineExpose, reactive, computed, watch, defineEmits } from 'vue';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import SesmtService from '../../../../services/SesmtService';
import { useAlert } from '../../../../composables/useAlert';

import DropdownElaboradorPgr from './DropdownElaboradorPgr.vue';

const service = new SesmtService('aplicacao-pgr-elaboracao/liberar');
const { alertErrorData, alertSucess } = useAlert();

const emit = defineEmits(['onSuccessElaboracao']);
const isOpen = ref(false);
const isLoading = ref(false);
const form = ref({});
const submitted = ref(false);
const filtrosExtras = ref({ where: [] });
const customerBranchAtual = ref({});
const loadingElaborar = ref(false);

const rules = reactive({
    elaboradorPgr: {
        required: helpers.withMessage('O elaborador é obrigatório.', required),
        validacaoPersonalizadaElaboradorPgr: helpers.withMessage(
            'O elaborador selecionado não possui usuário vinculado.',
            (value) => !!value?.usuarioVinculadoId
        ),
        lazy: true
    },
    emails: {
        required: helpers.withMessage(
            'Ao menos um e-mail deve ser informado',
            requiredIf(() => form.value?.enviarPorEmail)
        ),
        format: helpers.withMessage('Pelo menos um dos e-mails informados não é válido.', (value) => {
            if (!form.value?.enviarPorEmail || !value || !value.length) {
                return true;
            }
            const emailsAlterados = value.map((p) => p.trim());
            return emailsAlterados.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
        }),
        lazy: true
    }
});

const v$ = useVuelidate(rules, form);

const isDisable = computed(
    () =>
        !form.value?.elaboradorPgr ||
        !form.value?.elaboradorPgr.usuarioVinculadoId ||
        (form.value?.enviarPorEmail && !form.value?.emails?.length) ||
        (v$.value.emails.$errors.length && v$.value.emails.lazy.$dirty)
);

watch(
    () => form.value.emails,
    async () => validarCampoEmail()
);

function updateEmails() {
    if (!enviarPorEmail) form.value.emails = [];
    const { enviarPorEmail, elaboradorPgr } = form.value;
    if (enviarPorEmail && elaboradorPgr?.usuarioVinculado?.email) {
        form.value.emails = [elaboradorPgr.usuarioVinculado.email];
    }
}

watch(() => form.value.elaboradorPgr, updateEmails);

watch(() => form.value.enviarPorEmail, updateEmails);

const validarCampoElaboradorPgr = () => {
    v$.value.elaboradorPgr.$touch();
    if (form.value?.elaboradorPgr?.usuarioVinculado?.email) form.value.emails = [form.value.elaboradorPgr.usuarioVinculado.email];
};

const validarCampoEmail = () => v$.value.emails.$touch();

const montarFiltroCliente = (customerId) => {
    if (customerId)
        filtrosExtras.value.where.push({
            prop: 'cliente.id',
            operator: 'equal',
            values: [customerId]
        });
};

const montarFiltroUnidade = (customerBranchId) => {
    if (customerBranchId)
        filtrosExtras.value.where.push({
            prop: 'customerBranch.id',
            operator: 'equal',
            values: [customerBranchId]
        });
};

const openDialog = async (data) => {
    isOpen.value = true;
    customerBranchAtual.value = data;
    montarFiltroCliente(data.cliente.id);
    montarFiltroUnidade(data.id);
};

const closeDialog = () => {
    isOpen.value = false;
    v$.value.$reset();
    form.value = {};
    filtrosExtras.value = { where: [] };
    submitted.value = false;
    loadingElaborar.value = false;
};

const elaborar = async () => {
    try {
        const isValid = await v$.value.$validate();

        if (!isValid) return;

        submitted.value = true;
        loadingElaborar.value = true;

        const profissionalSesmtEnviarEmail = form.value.enviarPorEmail ? form.value?.emails?.map((p) => p?.trim()) : [];

        await service.save({
            customerBranchesId: customerBranchAtual.value?.id,
            profissionalSesmtId: form.value?.elaboradorPgr.id,
            profissionalSesmtEnviarEmail
        });
        alertSucess('Liberação realizada com sucesso.');
        closeDialog();
        emit('onSuccessElaboracao');
    } catch (error) {
        if (error?.response?.data?.details?.response?.retornoErrosCadastro?.contemErrosCadastro) {
            closeDialog();
            return;
        }

        submitted.value = false;
        loadingElaborar.value = false;
        alertErrorData(error, 'Não foi possível liberar a visita técnica, por favor tente novamente.');
    }
};

defineExpose({
    openDialog
});
</script>
<template>
    <template>
        <Dialog
            v-model:visible="isOpen"
            :style="{ width: '430px' }"
            :header="`Liberar para elaboração ${customerBranchAtual?.name}`"
            @hide="closeDialog"
            :modal="true"
            :closable="!isLoading"
        >
            <div v-if="!isLoading" class="flex flex-column gap-2">
                <div class="flex flex-column field">
                    <label for="elaboradorPGR">Elaborador PGR</label>
                    <DropdownElaboradorPgr
                        id="elaboradorPGR"
                        class="w-full"
                        v-model="v$.elaboradorPgr.$model"
                        :filtrosExtras="null"
                        :class="{ 'p-invalid': v$.elaboradorPgr.$errors.length && v$.elaboradorPgr.lazy.$dirty }"
                        @change="validarCampoElaboradorPgr"
                        showClear
                    />
                    <small v-if="v$.elaboradorPgr.$errors.length && v$.elaboradorPgr.lazy.$dirty" class="p-error">
                        {{ v$.elaboradorPgr.$errors[0].$message }}
                    </small>
                </div>
                <div class="field-checkbox mb-5">
                    <InputSwitch id="enviarPorEmail" v-model="form.enviarPorEmail" />
                    <label for="enviarPorEmail">Enviar notificação para o elaborador</label>
                </div>

                <div class="field p-fluid">
                    <label for="emails">E-mail</label>
                    <Chips
                        id="emails"
                        :disabled="!form?.enviarPorEmail"
                        v-model="v$.emails.$model"
                        placeholder="Digite um e-mail"
                        separator=","
                        :class="{ 'p-invalid': v$.emails.$errors.length && v$.emails.lazy.$dirty }"
                        aria-describedby="emails-help"
                    />
                    <small v-if="!v$.emails.$errors.length" id="emails-help">Digite um email e pressione Enter para adicionar; repita para cada novo email.</small>
                    <small v-if="v$.emails.$errors.length && v$.emails.lazy.$dirty" class="p-error">
                        {{ v$.emails.$errors[0].$message }}
                    </small>
                </div>
                <div class="grupo-botoes-form">
                    <Button text label="Cancelar" class="p-button-outlined mr-4" :disabled="loadingElaborar" @click="closeDialog" />
                    <Button class="button-salvar" label="Liberar" :disabled="isDisable" :loading="loadingElaborar" @click="elaborar" />
                </div>
            </div>
        </Dialog>
    </template>
</template>
<style scoped lang="scss">
.grupo-botoes-form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    .button-link {
        margin-right: 20px;
    }
}
</style>
