<script setup>
import { defineProps, onBeforeMount, ref, onMounted } from 'vue';
import StatusGroLpp from '../../../../enums/StatusGroLpp';
import TipoInventarioEnum from '../../../../enums/TipoInventarioEnum';
import { getClientSesmt } from '../../../../services/http-sesmt';
import ProgressoStatus from './ProgressoStatus';

const props = defineProps({
    produtoServico: {
        type: Object
    },
    nomeAvaliador: {
        type: String
    },
    status: {
        type: String
    },
    customerBranchId: {
        type: Number
    },
    customerId: {
        type: Number
    }
});

const statusCustomerOptions = ref([]);
const progressoPGR = ref(0);
const isPGR = props.produtoServico.tipoInventario === TipoInventarioEnum.PGR;
const styleMapStatus = {
    [StatusGroLpp.SEM_DADOS]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroLpp.EM_ANDAMENTO]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroLpp.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    }
};

function getStatusCustomerOptions() {
    statusCustomerOptions.value = Object.keys(StatusGroLpp).map(function (type) {
        return {
            label: `${StatusGroLpp[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusGroLpp[type]]
        };
    });
}
async function getProgressoPGR() {
    if (isPGR) {
        const { data } = await getClientSesmt().get(`customer/hierarchies/cliente/${props.customerId}/unidade/${props.customerBranchId}/progresso`);
        progressoPGR.value = data;
    }
}

function getProgresso() {
    if (isPGR) {
        return progressoPGR.value;
    }
    return 0;
}
onMounted(() => {
    getProgressoPGR();
});
onBeforeMount(getStatusCustomerOptions);
</script>
<template>
    <div class="block-progress-status">
        <div class="header">
            <span class="title font-semibold line-height-3">
                <b>{{ props.produtoServico.nome }}</b>
            </span>
            <span>
                <b>Avaliador(a): {{ nomeAvaliador ? nomeAvaliador : 'Sem responsável' }}</b>
            </span>
        </div>
        <ProgressoStatus :statys="props.status" :progresso="getProgresso()" />
    </div>
</template>
<style lang="scss">
.block-progress-status {
    border-bottom: 1px solid #ededed;
    padding-bottom: 10px;
    width: 250px;
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .title {
            font-size: 17px;
        }
    }
    .footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        .contador {
            margin-right: 10px;
        }
    }
}
.p-progressbar .p-progressbar-value {
    background-color: #5e8f32 !important;
}
</style>
