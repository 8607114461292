<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '50%' }" header="Produtos contratados" @hide="close" :draggable="false" modal>
        <div v-if="!loading" class="grid">
            <div class="col-6" v-for="(item, index) in produtosOrdenados" :key="index">
                <ProdutoContratado
                    :produtoServico="item.produtoServico"
                    :nomeAvaliador="item.nomeAvaliador"
                    :status="item.statusGro"
                    :customerBranchId="item.customerBranchId"
                    :customerId="item.customerId"
                />
            </div>
        </div>
        <div v-else class="flex align-items-center justify-content-center flex-wrap gap-4 mt-3 py-8">
            <i class="pi pi-spin pi-spinner text-primary" style="font-size: 5rem"></i>
            <span class="flex align-items-center justify-content-center text-2xl" style="min-width: 100%">Carregando informações</span>
        </div>
        <template #footer>
            <Button label="Fechar" class="p-button-text justify-content-end" @click="close" />
        </template>
    </Dialog>
</template>

<script setup>
import { ref, defineExpose } from 'vue';
import EtapasInventarioRiscoEnum from '../../../../enums/EtapasInventarioRiscoEnum';
import TipoInventarioEnum from '../../../../enums/TipoInventarioEnum';
import { getClientSesmt } from '../../../../services/http-sesmt';
import ProdutoContratado from './ProdutoContratado.vue';

const isOpen = ref(false);
const record = ref(null);
const produtosOrdenados = ref([]);
const inventarioRiscoResponsaveis = ref([]);
const loading = ref(false);

function close() {
    isOpen.value = false;
}

async function openDialog(data) {
    loading.value = true;
    isOpen.value = true;
    record.value = data;

    try {
        await getResponsaveisInventarioRisco(record.value);

        produtosOrdenados.value = record.value.produtoServicoUnidade.map((item) => {
            return {
                produtoServico: item.produtoServico,
                nomeAvaliador: getAvaliadorConformeTipoProdutoServico(item.produtoServico.tipoInventario),
                statusGro: item.statusGro,
                customerBranchId: record?.value?.id,
                customerId: record?.value?.cliente?.id,
                ordem: getOrder(item.produtoServico.tipoInventario)
            };
        });
        produtosOrdenados.value = produtosOrdenados.value.sort((a, b) => a.ordem - b.ordem);

        loading.value = false;
    } catch (error) {
        loading.value = false;
    }
}

async function getResponsaveisInventarioRisco(record) {
    const { data } = await getClientSesmt().get(
        `inventario-hierarquia/find-aplicacao-levantamento-by/cliente/${record?.cliente?.id}/unidade/${record?.id}`
    );
    inventarioRiscoResponsaveis.value = data?.inventarioRisco?.inventarioRiscoResponsavel;
}

function getOrder(tipoInventario) {
    switch (tipoInventario) {
        case TipoInventarioEnum.LPP:
            return 0;
        case TipoInventarioEnum.PGR:
            return 1;
        case TipoInventarioEnum.AEP:
            return 2;
        case TipoInventarioEnum.AET:
            return 3;
        case TipoInventarioEnum.LTCAT:
            return 4;
        case TipoInventarioEnum.LTI:
            return 5;
        case TipoInventarioEnum.LTP:
            return 6;
        case TipoInventarioEnum.PCMSO:
            return 7;
    }
}

function getAvaliadorConformeTipoProdutoServico(tipo) {
    return inventarioRiscoResponsaveis.value?.find((p) => p.etapa === verificarTipoConformeEtapa(p.etapa, tipo))?.profissionalSesmt?.nome;
}

function verificarTipoConformeEtapa(etapa, tipo) {
    if (tipo === TipoInventarioEnum.LPP) return EtapasInventarioRiscoEnum.LEVANTAMENTO_PERIGO_RISCO;

    return tipo;
}

defineExpose({
    openDialog
});
</script>

<style></style>
