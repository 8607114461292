<script setup>
import { ref, defineExpose } from 'vue';
import { getClientSesmt } from '../../../../../services/http-sesmt';
import PanelLpp from './components/PanelLpp.vue';
import PanelPgr from './components/PanelPgr.vue';
import { useAlert } from '../../../../../composables/useAlert';

const isOpen = ref(false);
const loading = ref(false);
const erroBackEnd = ref(false);
const produtoElaboracao = ref({});
const { alertError } = useAlert();

const load = async (unidadeId, customerId) => {
    try {
        loading.value = true;
        const { data } = await getClientSesmt().get(`inventario-risco/detalhes-produtos-elaborados/cliente/${customerId}/unidade/${unidadeId}`);
        produtoElaboracao.value = { ...data };
    } catch (error) {
        erroBackEnd.value = true;
        alertError(error?.response?.data?.message);
    } finally {
        loading.value = false;
    }
};

const close = () => {
    isOpen.value = false;
    erroBackEnd.value = false;
};

const openDialog = async (data) => {
    const { id, customerId } = data;
    isOpen.value = true;
    await load(id, customerId);
};

defineExpose({
    openDialog
});
</script>

<style scoped lang="scss">
.dialog-content-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    gap: 1rem;
}
</style>

<template>
    <div>
        <Dialog v-model:visible="isOpen" :style="{ width: '477px' }" header="Mais detalhes" @hide="close" :modal="true">
            <div v-if="loading" class="flex align-content-center justify-content-center flex-wrap gap-4 mt-3">
                <i class="pi pi-spin pi-spinner text-primary" style="font-size: 5rem"></i>
                <span class="flex align-items-center justify-content-center text-2xl" style="min-width: 100%">Carregando informações...</span>
            </div>

            <div v-if="!loading && erroBackEnd" class="dialog-content-error">
                <i class="pi pi-times-circle text-pink-600" style="font-size: 8rem"></i>
                <span class="text-2xl" style="min-width: 100%"> Ocorreu um problema ao listar os detalhes dos produtos. </span>
            </div>

            <div v-if="!loading && !erroBackEnd">
                <PanelLpp v-if="produtoElaboracao?.produtoLpp?.inventarioHierarquiaId" :produtoLpp="produtoElaboracao.produtoLpp" />
                <PanelPgr v-if="produtoElaboracao?.produtoPgr?.pgrElaboracaoId" class="mt-3" :produtoPgr="produtoElaboracao.produtoPgr" />
                <span v-if="produtoElaboracao?.todosSemElaboracao">Não há informações a serem apresentadas.</span>
            </div>

            <template #footer>
                <Button label="Fechar" class="p-button-primary justify-content-end" @click="close" />
            </template>
        </Dialog>
    </div>
</template>
