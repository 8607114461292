import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export function useFiltrosUnidade(props, emit) {
    const router = useRouter();
    const store = useStore();

    const _customerId = computed({
        get() {
            return props.customerId;
        },
        set(value) {
            emit('update:customerId', value);
        }
    });

    const _filtrosExtras = computed({
        get() {
            return props.filtrosExtras;
        },
        set(value) {
            emit('update:filtrosExtras', value);
        }
    });

    async function filtrar() {
        await atualizarQueryParams();
        emit('loadCustomerBranches');
    }

    async function atualizarQueryParams() {
        const { customerBranch, status } = _filtrosExtras.value;
        store.dispatch('setQueryParamsTelaUnidadesGro', {
            customerBranch: customerBranch ? JSON.stringify(customerBranch.id) : undefined,
            status: status || undefined
        });
        await router.replace({
            query: {
                customerBranch: customerBranch ? JSON.stringify(customerBranch.id) : undefined,
                status: status || undefined
            }
        });
    }

    async function removerFiltros() {
        _filtrosExtras.value = {};
        await router.replace();
        emit('loadCustomerBranches', true);
    }

    return {
        _customerId,
        _filtrosExtras,
        filtrar,
        removerFiltros
    };
}
