<script setup>
import StatusGrid from '../StatusGrid.vue';
import { defineProps, onBeforeMount, ref } from 'vue';
import StatusGroCustomerBranchEnum from '../../../../enums/StatusGroCustomerBranch';

const statusCustomerOptions = ref([]);

const styleMapStatus = {
    [StatusGroCustomerBranchEnum.EM_ELABORACAO]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroCustomerBranchEnum.NOVA_UNIDADE]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    },
    [StatusGroCustomerBranchEnum.EM_VISITA_TECNICA]: {
        backgroundColor: '#F7FFC6',
        color: '#504D05'
    },
    [StatusGroCustomerBranchEnum.CONCLUIDO]: {
        backgroundColor: '#C3F6D9',
        color: '#0E4005'
    },
    [StatusGroCustomerBranchEnum.A_LIBERAR]: {
        backgroundColor: '#C3F3F6',
        color: '#0D0540'
    }
};

function getStatusComTodasAsConfigs(status) {
    return statusCustomerOptions.value.find((p) => p.label === status) ?? { label: 'NÃO INFORMADO OU INVÁLIDO' };
}

function getStatusCustomerOptions() {
    statusCustomerOptions.value = Object.keys(StatusGroCustomerBranchEnum).map(function (type) {
        return {
            label: `${StatusGroCustomerBranchEnum[type]}`,
            value: `${type}`,
            style: styleMapStatus[StatusGroCustomerBranchEnum[type]]
        };
    });
}
const props = defineProps({
    status: {
        type: String
    },
    progresso: {
        type: Number
    }
});
onBeforeMount(getStatusCustomerOptions);
</script>
<template>
    <div class="block-progress-status">
        <div class="main">
            <ProgressBar :showValue="false" style="height: 6px" :value="props.progresso" />
        </div>
        <div class="footer" v-if="props.status && statusCustomerOptions.length">
            <div class="contador">0/0</div>
            <StatusGrid :status="getStatusComTodasAsConfigs(props.status)" />
        </div>
    </div>
</template>
<style scoped lang="scss">
.block-progress-status {
    border-bottom: 1px solid #ededed;
    padding-bottom: 10px;
    width: 250px;
    .footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 10px;
        .contador {
            margin-right: 10px;
        }
    }
}
.p-progressbar .p-progressbar-value {
    background-color: #5e8f32 !important;
}
</style>
