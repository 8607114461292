<template>
    <Dialog 
        v-model:visible="isOpen"
        :style="{ width: '450px', maxHeight: '500px' }"
        :modal="true"
        :draggable="false"
        @hide="closeDialog"
    >
        <template #header>
            <div class="inline-flex align-items-center justify-content-center gap-3">
                <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                <span class="font-bold text-2xl">Falha na liberação</span>
            </div>
        </template>

        <Panel class="mb-2" v-for="(item, index) in listaDeErros" :key="index" :header="item.erro" toggleable :collapsed="true">
            <template #header>
                <div class="w-full py-3 text-xl font-medium">
                    {{ item.erro }}
                </div>
            </template>
            <p class="m-0">{{ item.descricaoErro }}</p>
            <Button label="Acessar" class="p-button-link p-0 mt-3" @click="acessarPath(item.pathFront)" />
        </Panel>

        <template #footer>
            <Button class="mt-4" label="Entendi" @click="closeDialog" />
        </template>
    </Dialog>
</template>

<script setup>
import { defineExpose, ref } from 'vue';

const isOpen = ref(false);
const listaDeErros = ref([]);

async function openDialog(errorsList) {
    isOpen.value = true;
    listaDeErros.value = errorsList;
}

function closeDialog() {
    isOpen.value = false;
    listaDeErros.value = [];
}

function acessarPath(path) {
    window.open(`${process.env.VUE_APP_WEB}#${path}`).focus();
}

defineExpose({
    openDialog
});
</script>
