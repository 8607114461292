<script setup>
import { computed, defineProps } from 'vue';
import moment from 'moment';

const props = defineProps({
    produtoPgr: Object
});

const dataComputed = computed({
    get: () => moment(props.produtoPgr.dataLiberacao).format('DD/MM/YYYY') || 'Data não preenchida'
});
</script>
<template>
    <Panel toggleable>
        <template #header>
            <div class="flex flex-column gap-2">
                <span class="font-bold">PGR</span>
                <span class="font-normal">Programa de gerenciamento de riscos</span>
            </div>
        </template>
        <div class="flex flex-column gap-2">
            <strong>Data da liberação da elaboração</strong>
            <span> {{ dataComputed }}</span>
        </div>

        <div class="flex flex-column gap-2 mt-3">
            <strong>Elaborador</strong>
            <span> {{ produtoPgr?.elaborador }}</span>
        </div>

        <div class="flex flex-column gap-2 mt-3">
            <strong>Notificado por e-mail</strong>
            <span> {{ produtoPgr?.notificadoPorEmail ? 'Sim' : 'Não' }}</span>
        </div>

        <div class="flex flex-column gap-2 mt-3">
            <strong>E-mail do avaliador</strong>
            <span> {{ produtoPgr?.emailDoElaborador ?? 'Nenhum e-mail informado' }}</span>
        </div>
    </Panel>
</template>
