<script setup>
import { computed, defineProps } from 'vue';
import moment from 'moment';

const props = defineProps({
    produtoLpp: Object
});

const dataComputed = computed({
    get: () => moment(props.produtoLpp.dataLiberacao).format('DD/MM/YYYY') || 'Data não preenchida'
});
</script>
<template>
    <Panel toggleable>
        <template #header>
            <div class="flex flex-column gap-2">
                <span class="font-bold">LPP</span>
                <span class="font-normal">Levantamento preliminar de perigo</span>
            </div>
        </template>

        <div class="flex flex-column gap-2">
            <strong>Data da liberação</strong>
            <span> {{ dataComputed }}</span>
        </div>

        <div class="flex flex-column gap-2 mt-3">
            <strong>Avaliador</strong>
            <span> {{ produtoLpp?.avaliador }}</span>
        </div>

        <div class="flex flex-column gap-2 mt-3">
            <strong>Notificado por e-mail</strong>
            <span> {{ produtoLpp?.notificadoPorEmail ? 'Sim' : 'Não' }}</span>
        </div>

        <div class="flex flex-column gap-2 mt-3">
            <strong>E-mail do avaliador</strong>
            <span> {{ produtoLpp?.emailDoAvaliador ?? 'Nenhum e-mail informado' }}</span>
        </div>

        <div class="flex flex-column gap-2 mt-3">
            <strong>Participantes</strong>
            <span> {{ produtoLpp?.participantes ?? 'Nenhum participante informado' }}</span>
        </div>
    </Panel>
</template>
