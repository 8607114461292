const TipoInventarioEnum = Object.freeze({
    LPP: 'LPP',
    LEVANTAMENTO: 'LEVANTAMENTO_PERIGO_RISCO',
    PGR: 'PGR',
    AEP: 'AEP',
    AET: 'AET',
    LTCAT: 'LTCAT',
    LTI: 'LTI',
    LTP: 'LTP',
    PCMSO: 'PCMSO',
    CONCLUSAO: 'CONCLUSAO'
});

export const TipoInventarioLabelEnum = new Map([
    [TipoInventarioEnum.LPP, 'Levantamento Preliminar de Perigo'],
    [TipoInventarioEnum.LEVANTAMENTO, 'Levantamento Perigo/Fator de Risco'],
    [TipoInventarioEnum.PGR, 'Inventário de risco'],
    [TipoInventarioEnum.AEP, 'Análise ergonômica'],
    [TipoInventarioEnum.AET, 'Análise ergonômica - AET'],
    [TipoInventarioEnum.LTCAT, 'Laudo'],
    [TipoInventarioEnum.LTI, 'Laudo - LTI'],
    [TipoInventarioEnum.LTP, 'Laudo - LTP'],
    [TipoInventarioEnum.PCMSO, 'Procedimentos'],
    [TipoInventarioEnum.CONCLUSAO, 'Conclusão'],
]);

export const TipoInventarioIconEnum = new Map([
    [TipoInventarioEnum.LPP, 'pi pi-exclamation-triangle'],
    [TipoInventarioEnum.LEVANTAMENTO, 'pi pi-exclamation-triangle'],
    [TipoInventarioEnum.PGR, 'pi pi-star-fill'],
    [TipoInventarioEnum.AEP, 'pi pi-box'],
    [TipoInventarioEnum.AET, 'pi pi-desktop'],
    [TipoInventarioEnum.LTCAT, 'pi pi-flag-fill'],
    [TipoInventarioEnum.LTI, 'pi pi-bookmark-fill'],
    [TipoInventarioEnum.LTP, 'pi pi-circle-fill'],
    [TipoInventarioEnum.PCMSO, 'pi pi-heart-fill'],
    [TipoInventarioEnum.CONCLUSAO, 'pi pi-check-circle'],
]);

export const TipoInventarioIconOrderEnum = (a, b) => {
    const ordenacoes = {};

    ordenacoes[TipoInventarioEnum.LPP] = 0;
    ordenacoes[TipoInventarioEnum.LEVANTAMENTO] = 0;
    ordenacoes[TipoInventarioEnum.AEP] = 1;
    ordenacoes[TipoInventarioEnum.AET] = 2;
    ordenacoes[TipoInventarioEnum.PGR] = 3;
    ordenacoes[TipoInventarioEnum.LTCAT] = 4;
    ordenacoes[TipoInventarioEnum.LTI] = 5;
    ordenacoes[TipoInventarioEnum.LTP] = 6;
    ordenacoes[TipoInventarioEnum.PCMSO] = 7;
    ordenacoes[TipoInventarioEnum.PCMSO] = 8;

    if (ordenacoes[a] < ordenacoes[b]) {
        return -1;
    }

    if (ordenacoes[a] > ordenacoes[b]) {
        return 1;
    }

    return 0;
}
  
export default TipoInventarioEnum;
