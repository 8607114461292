<template>
    <AppInfinityDropdown
        v-model="value"
        optionLabel="createAt"
        placeholder="Selecione uma visita"
        :service="$service"
        :renderValue="(slotProps) => formatDate(slotProps.value?.dataVisita) + ' (' + slotProps.value?.id + ')'"
        :renderOption="(slotProps) => formatDate(slotProps.option?.dataVisita) + ' (' + slotProps.option?.id + ')'"
        :filtrosExtras="filtrosExtras"
        :recordsPerPage="recordsPerPage"
    />
</template>
<script>
import * as moment from 'moment';
import SesmtService from '@/services/SesmtService';
export default {
    name: 'DropdownDataVersao',
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object
        },
        recordsPerPage: {
            type: Number,
            default: 20
        },
        filtrosExtras: {
            type: Object
        }
    },
    data() {
        return {
            $service: null
        };
    },
    methods: {
        formatDate(date) {
            return moment.tz(date, 'UTC').format('DD/MM/YYYY');
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    async created() {
        this.$service = new SesmtService('/inventario-risco-visita-tecnica');
    }
};
</script>
