<script setup>
import { ref, defineExpose } from 'vue';
import DialogPendenciaEfetivarConclusao from './DialogPendenciaEfetivarConclusao';
import { getClientSesmt } from '../../../services/http-sesmt';
import { useToast } from 'primevue/usetoast';

const isOpen = ref(false);
const close = () => (isOpen.value = false);
const dialogPendenciaEfetivarConclusao = ref(false);
const record = ref(null);
const toast = useToast();
const openDialog = async (data) => {
    record.value = data;
    isOpen.value = true;
};

async function confirmar() {
    try {
        const { data } = await getClientSesmt().post(`aplicacao-pgr-elaboracao/efetivar-validacao`, {
            unidadeId: record.value.id,
            pgrId: record.value.pgrElaboracao[0].id,
            inventarioRiscoVisitaTecnicaId: record.value.pgrElaboracao[0].inventarioRiscoVisitaTecnicaId,
            clienteId: record.value.customerId
        });
        const etapasPendentes = data;
        if (etapasPendentes) {
            close();
            dialogPendenciaEfetivarConclusao.value.openDialog(etapasPendentes);
            return;
        }

        toast.add({
            severity: 'success',
            summary: 'Conclusão efetivada com sucesso!',
            life: 3000
        });
        close();
    } catch (err) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao efetivar conclusão!',
            detail: err?.response?.data?.message || err?.message,
            life: 3000
        });
    }
}

defineExpose({
    openDialog
});
</script>
<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '33rem' }" header="Efetivar conclusão" @hide="close" :modal="true">
        <template #default>
            <span>Você deseja efetivar a conclusão?</span>
        </template>
        <template #footer>
            <Button label="Cancelar" text class="p-button-primary justify-content-end" @click="close" />
            <Button label="Confirmar" class="p-button-primary justify-content-end" @click="confirmar" />
        </template>
    </Dialog>
    <DialogPendenciaEfetivarConclusao ref="dialogPendenciaEfetivarConclusao" />
</template>
