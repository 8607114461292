<template>
    <Dialog v-model:visible="isOpen" :style="{ width: '550px' }" :modal="true" :draggable="false">
        <template #header>
            <div>
                <h2>Relatório enviado com sucesso</h2>
            </div>
        </template>

        <div class="flex flex-row justify-content-center align-items-center">
            <img src="../../../../public/layout/images/atualizacao-prestador/finalAtualizaPrestador.svg" alt="">
        </div>

        <template #footer>
            <div class="flex flex-row justify-content-end">
                <div>
                    <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import { defineExpose, ref } from 'vue';

const isOpen = ref(false);

async function openDialog() {
    isOpen.value = true;
}

function closeDialog() {
    isOpen.value = false;
}

defineExpose({
    openDialog
});
</script>
