const TipoProdutoServicoEnum = Object.freeze({
    LPP: 'Levantamento Premilimar de Perigo (LPP)',
    PGR: 'Programa de Gerenciamento de Riscos (PGR) ',
    AEP: 'Análise Ergonômica Preliminar (AEP)',
    AET: 'Análise Ergonômica do Trabalho (AET)',
    LTCAT: 'Laudo Técnico das Condições Ambientais de Trabalho (LTCAT)',
    LTI: 'Laudo Técnico de Insalubridade (LTI)',
    LTP: 'Laudo Técnico de Periculosidade (LTP)',
    PCMSO: 'Programa de Controle Médico de Saúde Ocupacional (PCMSO)'
});

export default TipoProdutoServicoEnum;